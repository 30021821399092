<template>
  <form class="search-form">
    <div class="form-body space-y-4">
      <slot />
    </div>
    <div class="form-actions gap-4">
      <slot name="actions" />
    </div>
  </form>
</template>

<style lang="postcss">
.search-form {
  @apply flex flex-row flex-wrap justify-between items-baseline gap-4 lg:flex-nowrap;
}

.community-form {
  @apply flex flex-grow;
}

.form-body {
  @apply flex-grow flex-shrink py-2;

  flex-basis: 70%;
}

.form-actions {
  @apply flex-grow flex-shrink py-2 flex;

  flex-basis: auto;
  flex-flow: column nowrap;
}

.full-width-text-field .field-is-text {
  @apply w-full;
}
.form-row {
  @apply flex flex-row flex-wrap justify-start items-center gap-4;
}

.form-field {
  @apply px-2 m-0 py-0 bg-white border-2 border-brand-turquoise;

  > .form-field-inline {
    @apply mb-0;
  }
}

.field-is-text:first-child {
  flex-grow: 1;
}

.field-is-composite {
  @apply p-0 bg-transparent border-none;
}

.form-field-inline {
  @apply inline-flex flex-row items-baseline gap-2 px-2 border-2 border-brand-turquoise;
}

.form-control {
  @apply bg-transparent border-none;

  &.fulltext {
    width: calc(100% - 30px);
  }
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

::placeholder {
  @apply uppercase;
}

.form-label-icon {
  @apply inline-block w-5 h-full;
}

.form-submit {
  @apply uppercase;

  &[disabled] {
    @apply bg-primary-300 text-gray-800 border-2 border-brand-turquoise cursor-not-allowed;
  }
}

.form-action {
  @apply lg:block py-1 px-2 transition-all duration-300;
  @apply uppercase bg-brand-peacock text-white text-sm tracking-[2px] px-8 py-[10px] border-2 border-brand-turquoise;

  &.is-primary {
    @apply bg-brand-peacock;
  }

  &.is-secondary {
    @apply bg-brand-sky;
  }

  &.form-reset {
    @apply text-sm text-brand-peacock bg-transparent border-transparent underline hover:no-underline tracking-[1px];
  }

  &[disabled] {
    @apply bg-primary-300 text-brand-peacock border-2 border-brand-turquoise;
  }
}

.form-reset {
  @apply text-sm text-brand-peacock tracking-[1px];
  background: transparent;
  border-color: transparent;
  text-decoration: underline;
}

.calendar-control {
  @apply max-w-[10ch] p-2;
}

.calendar-picker-icon {
  @apply text-gray-400;
}
</style>
