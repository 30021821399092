<template>
  <div class="field">
    <label class="form-label form-label-is-icon" for="fulltext">
      <span class="sr-only">{{ t('label') }}</span>
      <svg
        class="icon inline-block w-5 h-full"
        xmlns="http://www.w3.org/2000/svg"
        fill="#959A9A"
        viewBox="0 0 24 24"
      >
        <path
          id="Icon_awesome-search"
          class="st0"
          d="M21.0822,18.4832l-4.164-4.164c-0.1884-0.1879-0.4439-0.293-0.71-0.292h-0.681
                c2.9513-3.7797,2.2797-9.2363-1.5-12.1877s-9.2363-2.2797-12.1877,1.5s-2.2797,9.2363,1.5,12.1877
                c3.1406,2.4522,7.5471,2.4522,10.6877,0v0.681c-0.001,0.2661,0.1041,0.5216,0.292,0.71l4.164,4.164
                c0.39,0.391,1.0232,0.3918,1.4142,0.0018c0.0006-0.0006,0.0012-0.0012,0.0018-0.0018l1.182-1.182
                C21.47,19.5081,21.4705,18.8759,21.0822,18.4832z M8.6822,14.0272c-2.9525,0-5.346-2.3935-5.346-5.346s2.3935-5.346,5.346-5.346
                s5.346,2.3935,5.346,5.346c0.0017,2.9509-2.3891,5.3443-5.34,5.346c-0.003,0-0.006,0-0.009,0L8.6822,14.0272z"
        />
      </svg>
    </label>
    <input
      id="fulltext"
      v-model.trim="input"
      type="text"
      :placeholder="t('placeholder')"
      class="fulltext form-control"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const input = defineModel<string>()
</script>

<i18n lang="json">
{
  "en": {
    "label": "Enter product Title, Author, or Keyword",
    "placeholder": "Title, Author, or Keyword"
  },
  "es": {
    "label": "Ingresa los términos de búsqueda",
    "placeholder": "Ingresa los términos de búsqueda"
  }
}
</i18n>
